import { useState, useEffect, useMemo } from "react";
import type { MetaFunction } from "@remix-run/node";
import { useLoaderData, useNavigate, useLocation } from "@remix-run/react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { TrashIcon, PhoneIcon, HomeIcon, EnvelopeIcon } from "@heroicons/react/24/solid";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import first from "lodash/first";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import ky from "ky";

// Extend dayjs with the duration plugin
dayjs.extend(duration);

export const meta: MetaFunction = () => {
  return [{ title: "Hagberg" }, { name: "description", content: "Kvalitatīvas dzīves telpas radīšana" }];
};

export const loader = async ({ params }) => {
  const { taskID } = params;
  // Fetch task & task metadata
  try {
    const task = await ky
      .get(`https://api.gsmtasks.com/tasks/${taskID}/?sideload=assignees,signatures,metadatas`, {
        headers: {
          Accept: "application/json; version=2.4.42",
          Authorization: `Token ${process.env.GSMTASKS_API_TOKEN}`,
        },
      })
      .json();

    const accountRoles = await ky
      .get(`https://api.gsmtasks.com/account_roles/?is_active=true`, {
        headers: {
          Accept: "application/json; version=2.4.42",
          Authorization: `Token ${process.env.GSMTASKS_API_TOKEN}`,
        },
      })
      .json();

    const workOrder = await ky
      .get(`https://requestcapture.gsmtasks.com/integrations/hagberg/work-orders/${taskID}/`)
      .json();

    const relatedTasks = [];
    if (workOrder.assignees.length > 1) {
      map(workOrder.assignees, (a) => {
        if (a.user_url !== first(task.tasks).assignee) {
          relatedTasks.push(
            ky
              .get(`${a.task_url}?sideload=metadatas`, {
                headers: {
                  Accept: "application/json; version=2.4.42",
                  Authorization: `Token ${process.env.GSMTASKS_API_TOKEN}`,
                },
              })
              .json()
          );
        }
      });
    }

    return {
      task: first(task.tasks),
      assignee: first(task.assignees),
      metadata: first(task.metadatas),
      signatures: task.signatures,
      accountRoles: accountRoles,
      workOrder: workOrder,
      relatedTasks: await Promise.all(relatedTasks),
    };
  } catch (error) {
    throw new Error(await error.response.text());
  }
};

const DynamicTable = ({ control, name, fields, register, totalLabel }) => {
  const {
    fields: rows,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
  });
  const watchedFields = useWatch({
    control,
    name: name,
  });
  const [totals, setTotals] = useState([]);

  /// Initialize with 3 empty rows if the table is empty
  useEffect(() => {
    if (rows.length === 0) {
      const emptyRow = fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {});
      for (let i = 0; i < 2; i++) {
        append(emptyRow);
      }
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const calculateTotals = () => {
      return watchedFields.map((row) => {
        const quantity = parseFloat(row.quantity) || 0;
        const price = parseFloat(row.price) || 0;
        return (quantity * price).toFixed(2);
      });
    };
    setTotals(calculateTotals());
  }, [watchedFields]);

  const grandTotal = useMemo(() => {
    return totals.reduce((sum, total) => sum + parseFloat(total), 0).toFixed(2);
  }, [totals]);

  return (
    <table className="w-full mb-6">
      <thead className="bg-green-100">
        <tr>
          <th className="border px-4 py-2 w-16">Nr.</th>
          {fields.map((field) => (
            <th key={field.name} className="border px-4 py-2">
              {field.label}
            </th>
          ))}
          <th className="border px-4 py-2 print:hidden"></th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={row.id}>
            <td className="border px-4 py-2 text-center">
              <span className="font-medium">{index + 1}</span>
            </td>
            {fields.map((field) => (
              <td key={field.name} className="border px-4 py-2">
                {field.type === "select" ? (
                  <>
                    <select
                      {...register(`${name}.${index}.${field.name}`)}
                      className="w-full p-1 border rounded print:hidden"
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <span className="screen:hidden">{get(watchedFields, `${index}.${field.name}`)}</span>
                  </>
                ) : field.type === "static" ? (
                  <span className="block w-full p-1 text-center">{get(watchedFields, `${index}.${field.name}`)}</span>
                ) : field.name === "total" ? (
                  <>
                    <input
                      {...register(`${name}.${index}.${field.name}`)}
                      className="w-full p-1 bg-gray-100 print:hidden"
                      type="number"
                      value={totals[index] || ""}
                      readOnly
                    />
                    <span className="screen:hidden">{get(watchedFields, `${index}.${field.name}`)}</span>
                  </>
                ) : (
                  <>
                    <input
                      {...register(`${name}.${index}.${field.name}`)}
                      className="w-full p-1 print:hidden"
                      type={field.type || "text"}
                      step=".01"
                    />
                    <span className="screen:hidden">{get(watchedFields, `${index}.${field.name}`)}</span>
                  </>
                )}
              </td>
            ))}
            <td className="border px-4 py-2 text-center print:hidden">
              {rows.length > 1 && (
                <button type="button" onClick={() => remove(index)} className="text-red-500">
                  <TrashIcon className="h-6 w-6" />
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="bg-gray-100 font-semibold">
          <td colSpan={fields.length} className="border px-4 py-2 text-right">
            {totalLabel}
          </td>
          <td className="border px-4 py-2 text-right">{grandTotal}</td>
          <td className="border px-4 py-2 print:hidden"></td>
        </tr>
        <tr className="print:hidden">
          <td colSpan={fields.length} className="py-2">
            <button
              type="button"
              onClick={() => append(fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}))}
              className="bg-white text-gray-700 border border-gray-300 hover:bg-gray-100 px-3 py-1 rounded transition duration-200 ease-in-out"
            >
              Pievienot rindu
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const WorkersTable = ({ workers }) => {
  function parseDuration(durationString) {
    // Split the duration string into its components
    const [time, microseconds] = durationString.split(".");
    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Create a duration object
    return dayjs.duration({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      milliseconds: microseconds ? Math.floor(Number(`0.${microseconds}`) * 1000) : 0,
    });
  }

  function formatDuration(durationObj) {
    // Format the duration as HH:mm
    const formattedHours = Math.floor(durationObj.asHours()).toString().padStart(2, "0");
    const formattedMinutes = durationObj.minutes().toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  // Calculate total work hours using useMemo
  const totalWorkHours = useMemo(() => {
    return workers.reduce((total, worker) => {
      const transitDuration = worker?.metadata?.transit_duration ? parseDuration(worker.metadata.transit_duration) : 0;
      const activeDuration = worker?.metadata?.active_duration ? parseDuration(worker?.metadata?.active_duration) : 0;
      return total.add(transitDuration).add(activeDuration);
    }, dayjs.duration(0));
  }, [workers]);

  return (
    <table className="w-full mb-6">
      <thead className="bg-green-100">
        <tr>
          <th className="border px-4 py-2">Nr.</th>
          <th className="border px-4 py-2">Darba izpildītājs</th>
          <th className="border px-4 py-2">Darbs sākts</th>
          <th className="border px-4 py-2">Darbs pabeigts</th>
          <th className="border px-4 py-2">Laiks ceļā</th>
          <th className="border px-4 py-2">Laiks objektā</th>
        </tr>
      </thead>
      <tbody>
        {workers.map((worker, index) => (
          <tr key={index}>
            <td className="border px-4 py-2">{index + 1}</td>
            <td className="border px-4 py-2">{worker?.accountRole?.display_name}</td>
            <td className="border px-4 py-2 text-right">
              {worker?.metadata?.last_active_at ? dayjs(worker.metadata.last_active_at).format("HH:mm") : "-"}
            </td>
            <td className="border px-4 py-2 text-right">
              {worker?.metadata?.last_completed_at ? dayjs(worker.metadata.last_completed_at).format("HH:mm") : "-"}
            </td>
            <td className="border px-4 py-2 text-right">
              {worker?.metadata?.transit_duration
                ? formatDuration(parseDuration(worker.metadata.transit_duration))
                : "-"}
            </td>
            <td className="border px-4 py-2 text-right">
              {worker?.metadata?.active_duration ? formatDuration(parseDuration(worker.metadata.active_duration)) : "-"}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="bg-green-100">
        <tr>
          <td colSpan="5" className="border px-4 py-2 text-right font-semibold">
            Laiks darba stundas kopā
          </td>
          <td className="border px-4 py-2 text-right font-semibold">{formatDuration(totalWorkHours)}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { task, assignee, metadata, signatures, accountRoles, workOrder, relatedTasks } = useLoaderData();
  const metadatas = flatMap(relatedTasks, "metadatas");

  const { control, register, watch, handleSubmit } = useForm({
    defaultValues: !isEmpty(workOrder.form_data)
      ? workOrder.form_data
      : {
          works: [],
          materials: [],
          transport: [],
        },
  });

  const workers = [{ accountRole: find(accountRoles, { user: assignee.url }), metadata: metadata }];
  map(workOrder.assignees, (a) => {
    if (a.user_url !== assignee.url) {
      workers.push({
        accountRole: find(accountRoles, { user: a.user_url }),
        metadata: find(metadatas, { task: a.task_url }),
      });
    }
  });

  const watchedFields = useWatch({
    control,
    name: ["works", "materials", "transport"],
  });
  const values = watch();

  const onSubmit = async (data) => {
    const response = await ky.post("https://requestcapture.gsmtasks.com/integrations/hagberg/work-orders/", {
      json: { task_url: task.url, form_data: data },
    });
    navigate(`${location.pathname}?tasklinkclose=true`);
  };

  const grandTotal = useMemo(() => {
    return watchedFields
      .reduce((sum, table) => {
        return (
          sum +
          table.reduce((tableSum, row) => {
            const quantity = parseFloat(row.quantity) || 0;
            const price = parseFloat(row.price) || 0;
            return tableSum + quantity * price;
          }, 0)
        );
      }, 0)
      .toFixed(2);
  }, [watchedFields]);

  const getLatestSignature = (signatures) => {
    if (!signatures || signatures.length === 0) {
      return null;
    }

    return signatures.reduce((latest, current) => {
      const latestDate = dayjs(latest.created_at);
      const currentDate = dayjs(current.created_at);
      return currentDate.isAfter(latestDate) ? current : latest;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white p-8 max-w-4xl mx-auto screen:shadow-lg mt-4">
        {/* Header */}
        <div className="flex justify-between mb-6">
          <div className="text-4xl font-bold text-green-500">
            <img src="/logo.png" alt="Hagberg Logo" className="w-16 inline-block mr-4" />
          </div>
          <div className="text-xl font-semibold text-green-500">Kvalitatīvas dzīves telpas radīšana</div>
        </div>

        {/* Document Info */}
        <div className="mb-6">
          <p className="font-semibold">Objektā izpildīto darbu akts Nr.: {task.reference}</p>
          <p>Datums: {dayjs(task.created_at).format("DD-MM-YYYY")}</p>
        </div>

        {/* Client and Object Info */}
        <div className="border border-gray-300 p-4 mb-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p>
                <span className="font-semibold">Klients:</span> {get(task, "contact.company", "-")}
              </p>
              <p>
                <span className="font-semibold">Adrese:</span> {task.address.formatted_address}
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">Objekts:</span> {get(task, "contact.name", "-")}
              </p>
              <p>
                <span className="font-semibold">Izaukuma iemesls:</span> {get(task, "description", "-")}
              </p>
            </div>
          </div>
        </div>

        {/* Additional Notes Text Area */}
        <div className="mb-6">
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
            Papildu piezīmes
          </label>
          <textarea
            id="notes"
            {...register("notes")}
            rows={3}
            className="mt-1 block w-full border border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 px-3 py-2 print:hidden"
            placeholder="Ievadiet papildu piezīmes šeit..."
          />
          <div className="border border-gray-300 p-4 mb-6 whitespace-pre-wrap screen:hidden">{values.notes}</div>
        </div>

        {/* Employee Work Table */}
        <WorkersTable workers={workers} />

        {/* Work Items Table */}
        <DynamicTable
          control={control}
          name="works"
          fields={[
            { name: "description", label: "Izpildītā darba apraksts" },
            { name: "unit", label: "Mērvienība", type: "static", value: "h" },
            { name: "quantity", label: "Daudzums", type: "number" },
            { name: "price", label: "Cena", type: "number" },
            { name: "total", label: "Summa", type: "number" },
          ]}
          register={register}
          totalLabel="Darba izmaksas"
        />

        {/* Materials Table */}
        <DynamicTable
          control={control}
          name="materials"
          fields={[
            { name: "description", label: "Izmantotie materiāli" },
            {
              name: "unit",
              label: "Mērvienība",
              type: "select",
              options: [
                { value: "gab", label: "gab" },
                { value: "m", label: "m" },
                { value: "kopm.", label: "kopm." },
                { value: "m2", label: "m2" },
              ],
            },
            { name: "quantity", label: "Daudzums", type: "number" },
            { name: "price", label: "Cena", type: "number" },
            { name: "total", label: "Summa", type: "number" },
          ]}
          register={register}
          totalLabel={"Izmantoto materiālu izmaksas"}
        />

        {/* Transport Table */}
        <DynamicTable
          control={control}
          name="transport"
          fields={[
            { name: "description", label: "Transports/Ierašanās objektā" },
            {
              name: "unit",
              label: "Mērvienība",
              type: "select",
              options: [
                { value: "km", label: "km" },
                { value: "Fiksētā", label: "Fiksētā" },
              ],
            },
            { name: "quantity", label: "Daudzums", type: "number" },
            { name: "price", label: "Cena", type: "number" },
            { name: "total", label: "Summa", type: "number" },
          ]}
          register={register}
          totalLabel={"Transporta izmaksas"}
        />

        {/* Total */}
        <div className="text-right font-semibold text-xl mb-6">
          <p>Kopā (bez PVN): {grandTotal}</p>
          {/* Submit Button */}
          <button
            type="submit"
            className="bg-blue-500 text-white px-2 py-1 mt-4 rounded hover:bg-blue-600 transition duration-200 ease-in-out print:hidden"
          >
            Saglabāt
          </button>
        </div>

        {/* Signer information */}
        <div className="mt-8 grid grid-cols-3 gap-4">
          <div>
            <p className="font-bold mb-2">Pasūtītāja pārstāvis</p>
            {/* Initial value should come from metafield gsmtasks:customercalled */}
            <input
              {...register("assigneeType")}
              className="w-3/4 text-sm p-2 border border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 rounded print:hidden"
              placeholder="Levadīt tekstu"
            />
            <span className="screen:hidden">{values.assigneeType}</span>
          </div>
          <div>
            <p className="font-bold mb-2">Vārds,uzvārds</p>
            <p>{get(getLatestSignature(signatures), "signer.name", "-")}</p>
          </div>
          <div className="flex flex-col items-end mb-2">
            <p className="font-bold">Paraksts</p>
            {get(getLatestSignature(signatures), "file") ? (
              <img src={getLatestSignature(signatures).file} alt="Customer Signature" />
            ) : (
              <p>-</p>
            )}
          </div>
        </div>

        {/* Footer */}
        <footer className="mt-8 text-sm text-gray-700 border-t pt-4">
          {get(task, ["metafields", "gsmtasks:customertype"], "") === "Private" && (
            <>
              <div className="grid grid-cols-3 gap-0 border border-gray-300 mb-4">
                <div className="p-2 font-bold text-center bg-gray-100 border-b border-r border-gray-300">
                  Standarta izsaukums
                  <br />
                  08.00 – 18.00
                </div>
                <div className="p-2 font-bold text-center bg-gray-100 border-b border-r border-gray-300">
                  Standarta+ izsaukums
                  <br />
                  pēc 18.00 darba dienās vai brīvdienās
                </div>
                <div className="p-2 font-bold text-center bg-gray-100 border-b border-gray-300">Avārijas izsaukums</div>
                <div className="col-span-3 p-2 text-center border-b border-gray-300">
                  Minimālais darba apjoms 1 stunda. Ja darbs ir mazāk kā 1 stunda, darba samaksa tiek aprēķināta kā par
                  vienu darba stundu!
                </div>
                <div className="p-2 border-r border-gray-300">
                  <p>1 stundas darbs un izsaukums</p>
                  <p className="font-bold">45,00 EUR *</p>
                  <p>Katras nākamās 30 minūtes</p>
                  <p className="font-bold">22,50 EUR *</p>
                </div>
                <div className="p-2 border-r border-gray-300">
                  <p>1 stundas darbs un izsaukums</p>
                  <p className="font-bold">55,00 EUR *</p>
                  <p>Katras nākamās 30 minūtes</p>
                  <p className="font-bold">27,50 EUR *</p>
                </div>
                <div className="p-2">
                  <p>1 stundas darbs un izsaukums</p>
                  <p className="font-bold">60,00 EUR *</p>
                  <p>Katras nākamās 30 minūtes</p>
                  <p className="font-bold">30 EUR *</p>
                </div>
                <div className="col-span-3 p-2 text-center border-t border-gray-300">
                  *Augstāk norādītās cenas norādītas ar PVN (21%) un attiecas tikai uz darbu, tajās nav iekļautas
                  izmaksas par materiāliem.
                  <br />
                  Katrs nākamais izcenojuma periods tiek piemērots, ja darbs aizņem ilgāk par 15 min.
                </div>
              </div>

              <p className="mb-4">
                <strong>
                  Pakalpojuma izmaksas sastāv no darba izmaksām un materiālu izmaksām. Darbu izmaksu izcenojumi:
                </strong>
              </p>

              <p className="mb-4">
                Parakstot šo aktu Pasūtītājs apliecina, ka <strong>tam</strong> nav nekādu pretenziju pret Izpildītāju,
                tas pieņem Izpildītāja sniegto/os Pakalpojumu/us tādā kvalitātē, kādā Izpildītājs tos ir veicis, un
                apņemas veikt samaksu par sniegtajiem Pakalpojumiem. Puses vienojas, ka Pakalpojuma summa tiks izstādīta
                nākamajā apsaimniekošanas rēķinā un apmaksa veicama līdz rēķinā norādītajam termiņam.
              </p>

              <p className="mb-4">
                <strong>
                  Pasūtītājs ar savu parakstu apliecina, ka tam ir visas likumiskās tiesības, juridiskais pamats un
                  attiecīgs pilnvarojums, lai pieņemtu no Izpildītāja sniegto/os Pakalpojumu/us un parakstītu Aktu.
                </strong>
              </p>

              <p>Akts sastādīts uz 1 (vienas) lapas divos eksemplāros, pa vienam eksemplāram katrai Pusei.</p>
            </>
          )}

          {/* Company Information */}
          <div className="mt-8 grid grid-cols-3 gap-4 text-green-500">
            <div>
              <p className="font-bold">SIA "HAGBERG"</p>
              <p>Kvalitatīvas dzīves telpas radīšana</p>
              <p>"Ozolkalni B", Mārupes pag., Mārupes nov., LV-2167</p>
            </div>
            <div className="text-center">
              <p>Reģ. Nr. 40103233073</p>
              <p>Swedbank AS, HABALV22</p>
              <p>LV17HABA0551053017874</p>
            </div>
            <div className="flex flex-col items-end">
              <p className="flex items-center">
                <PhoneIcon className="mr-2 w-4 h-4" /> +371 25738833
              </p>
              <p className="flex items-center">
                <EnvelopeIcon className="mr-2 w-4 h-4" /> info@hagberg.lv
              </p>
              <p className="flex items-center">
                <HomeIcon className="mr-2 w-4 h-4" /> www.hagberg.lv
              </p>
            </div>
          </div>
        </footer>
      </div>
    </form>
  );
}
